import React from 'react';

const RocketInfo = ({name, active, stages, boosters, first_flight, company, country, success_rate_pct, cost_per_launch, description}) => {
  return (
    <article className="  xs:col-span-12 md:col-span-2 lg:border-r-2 lg:border-gray-200 px-2 md:pt-3 ...">
    <h3 className='text-xl font-medium text-gray-600'>
        Rocket info
      </h3>
      
      {name !== null ? <p className="text-gray-600 font-semibold">Name: <span className="text-gray-600 font-normal">{name}</span></p>: ""}
      {active === true ? <p className="text-gray-600 font-semibold">Active status: <span className="text-green-600 font-normal">Active</span></p>: <p>Active status: <span className="text-red-600">Not Active</span></p>}
      {stages !== null ? <p className="text-gray-600 font-semibold">Stages: <span className="text-gray-600 font-normal">{stages}</span></p>: ""}
      {boosters !== null ? <p className="text-gray-600 font-semibold">Boosters: <span className="text-gray-600 font-normal">{boosters}</span></p>: ""}
      {first_flight !== null ? <p className="text-gray-600 font-semibold">First Flight: <span className="text-gray-600 font-normal">{first_flight}</span></p>: ""}
      {company !== null ? <p className="text-gray-600 font-semibold">Company: <span className="text-gray-600 font-normal">{company}</span></p>: ""}
      {country !== null ? <p className="text-gray-600 font-semibold">Country: <span className="text-gray-600 font-normal">{country}</span></p>: ""}
      {success_rate_pct !== null ? <p className="text-gray-600 font-semibold">Success Rate: <span className="text-gray-600 font-normal">{success_rate_pct}%</span></p>: ""}
      {cost_per_launch !== null ? <p className="text-gray-600 font-semibold">Cost per Launch: <span className="text-gray-600 font-normal">${cost_per_launch}</span></p>: "Unknown"}
      {description !== null ? <p className="text-gray-600 font-semibold">Description: <span className="text-gray-600 font-normal">{description}</span></p>: ""}
    </article>
  );
};

export default RocketInfo;


// {rocketData && rocketData.name !== null ? <p className="text-gray-600 font-semibold">Name: <span className="text-gray-600 font-normal">{rocketData.name}</span></p>: ""}
// {rocketData && rocketData.active === true ? <p className="text-gray-600 font-semibold">Active status: <span className="text-green-600 font-normal">Active</span></p>: <p>Active status: <span className="text-red-600">Not Active</span></p>}
// {rocketData && rocketData.stages !== null ? <p className="text-gray-600 font-semibold">Stages: <span className="text-gray-600 font-normal">{rocketData.stages}</span></p>: ""}
// {rocketData && rocketData.boosters !== null ? <p className="text-gray-600 font-semibold">Boosters: <span className="text-gray-600 font-normal">{rocketData.boosters}</span></p>: ""}
// {rocketData && rocketData.first_flight !== null ? <p className="text-gray-600 font-semibold">First Flight: <span className="text-gray-600 font-normal">{rocketData.first_flight}</span></p>: ""}
// {rocketData && rocketData.company !== null ? <p className="text-gray-600 font-semibold">Company: <span className="text-gray-600 font-normal">{rocketData.company}</span></p>: ""}
// {rocketData && rocketData.country !== null ? <p className="text-gray-600 font-semibold">Country: <span className="text-gray-600 font-normal">{rocketData.country}</span></p>: ""}
// {rocketData && rocketData.success_rate_pct !== null ? <p className="text-gray-600 font-semibold">Success Rate: <span className="text-gray-600 font-normal">{rocketData.success_rate_pct}%</span></p>: ""}
// {rocketData && rocketData.cost_per_launch !== null ? <p className="text-gray-600 font-semibold">Cost per Launch: <span className="text-gray-600 font-normal">${rocketData.cost_per_launch}</span></p>: "Unknown"}
// {rocketData && rocketData.description !== null ? <p className="text-gray-600 font-semibold">Description: <span className="text-gray-600 font-normal">{rocketData.description}</span></p>: ""}
