import React from 'react'
import HeroById from './HeroById'

const ScratchPage = () => {
  return (
    <div className='container px-5'>
      Launch design page

    <HeroById />


{/* <div className="grid grid-cols-12">
  <aside className="col-span-2">Sidebar</aside>
  <main className="col-span-7">Main [wider] content</main>
  <div className="col-span-3">Another sidebar... why not?</div>
</div>


<div className="grid sm:grid-rows-3  md:grid-rows-3  gap-4 mt-5">
  <div className="row-span-3 sm:col-span-1 md:col-span-2 bg-gray-100 ...">1</div>
  <div className="col-span-1 bg-gray-200 ...">2</div>
  <div className="row-span-2 col-span-1 bg-gray-300 ...">3</div>
</div> */}


{/* <div className="flex mt-5">
  <div className="flex-2 ...">
     Will grow and shrink as needed without taking initial size into account 
  </div>

  <div className="flex-none md:flex-1 ...">
     Will grow and shrink as needed without taking initial size into account 
  </div>
</div> */}




{/* <div className="grid sm:grid-rows-1 md:grid-rows-3   grid-flow-col gap-4 ...">

  <div className="row-span-3 sm:col-span-12 md:col-span-2 bg-gray-50 ...">1</div>

  <div className="xs:col-span-8 sm:col-span-8 md:col-span-2 bg-yellow-500 sm:mt-5 ...">2</div>

  <div className="row-span-2 xs:col-span-8 sm:col-span-2 bg-green-200 md:mt-56 ...">3</div>
</div> */}


{/* <div className="grid grid-cols-3 gap-4">
  <div className="...">1</div>
  <div className="...">2</div>
  <div className="...">3</div>
  <div className="col-span-2 ...">4</div>
  <div className="...">5</div>
  <div className="...">6</div>
  <div className="col-span-2 ...">7</div>
</div> */}

{/* <div className="grid xs:grid-rows-6 md:grid-rows-3 md:grid-flow-row  gap-4 mt-5 ...">
  <div className=' bg-gray-500  h-24 ...'>1</div>
  <div className='bg-green-200'>2</div>
  <div>3</div>
  <div>4</div>
  <div>5</div>
  <div>6</div>
  <div>7</div>
  <div>8</div>
  <div>9</div>
</div> */}

   



      {/* <div className="grid grid-cols-3 gap-4">
  <div className="...">01</div>
  <div className="...">02</div>
  <div className="...">03</div>
  <div className="col-span-2 ...">04</div>
  <div className="...">05</div>
  <div className="...">06</div>
  <div className="col-span-2 ...">07</div>
</div> */}
    </div>
  )
}

export default ScratchPage
