// import { Launch } from '@material-ui/icons';
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
// import HeroById from "./HeroById";
import ReactPlayer from 'react-player'
import moment from 'moment';
import PayloadInfo from "./PayloadInfo";
import RocketInfo from "./RocketInfo";
moment.locale('cs');
// import { MailIcon, PhoneIcon } from '@heroicons/react/solid'

const LaunchById = () => {
  const { id } = useParams();
  // const [launchId, setLaunchId] = useState("");
  // const [cores, setCores ] = useState('')
  // const [payload, setPayload] = useState([]);
  // const [rocket, setRocket] = useState([]);
  // const [rocketData, setRocketData] = useState('');
  // const [payloadById, setPayloadById] = useState([]);

  const [launchId, setLaunchId] = useState("");
  const [payload, setPayload] = useState([]);
  const [rocket, setRocket] = useState('');
  const [cores, setCores ] = useState('')
  const [rocketData, setRocketData] = useState('');
  const [payloadById, setPayloadById] = useState([]);

  

  useEffect(() => {

    const abortController = new AbortController()
    const signal = abortController.signal

    axios
      .get(`https://api.spacexdata.com/v4/launches/${id}`)
      .then((response) => {
        setLaunchId(response.data);
      })
      .catch((error) => {
        console.log(error)
      });

    // console.log(rocket.id);
    console.log("Payload from launch ", launchId && launchId.payloads[0])
    


    // cores by id
    axios
      .get('https://api.spacexdata.com/v4/cores/5e9e289ef359184f103b2627')
      .then((response) => {
        setCores(response.data);
      })
      .catch((error) => {});

    //   let payloadData = ""

    //   if(launchId && launchId.payloads[0] !== null){
    //     payloadData = launchId && launchId.payloads[0]
    //   } else if (launchId && launchId.payloads[1] !== null){
    //     payloadData = launchId && launchId.payloads[1]
    //   } else {
    //     console.log("payload data can't be recieved")
    //   }
    //   console.log(payloadData)

    //  let pay = payloadData

      axios
      // .get(`https://api.spacexdata.com/v4/payloads/${pay}`)
      .get('https://api.spacexdata.com/v4/payloads/5eb0e4b5b6c3bb0006eeb1e1')
      .then((res) => {
        setPayloadById(res.data);
      })
      .catch((error) => {
        console.log("Payload error msg: ", error)
      });

// payload by id 
// if(payloadData !== null){
//   // setPayload(launchId && launchId.payloads[0]);
//   axios
//       .get(`https://api.spacexdata.com/v4/payloads/${payloadData}`)
//       // .get('https://api.spacexdata.com/v4/payloads/5eb0e4b5b6c3bb0006eeb1e1')
//       .then((res) => {
//         setPayloadById(res.data);
//         // console.log(payloadById.name);
//       })
//       .catch((error) => {
//         console.log("Payload error msg: ", error)
//       });
//   // console.log(payload)
// } else {
//   // console.log("payload error")
// }
    

      // console.log(launchId.rocket)
      // setRocket(launchId && launchId.rocket)
      console.log("Rocket Id from launch ",launchId.rocket)
      // console.log(rocket)
      // Rocket by id 
    axios
      .get(`https://api.spacexdata.com/v4/rockets/${launchId.rocket}`)
      // .get('https://api.spacexdata.com/v4/rockets/5e9d0d95eda69973a809d1ec')
      .then((res) => {
        setRocketData(res.data);
      })
      .catch((error) => {
        console.log("Rocket error msg: ", error)
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <div className="bg-white container">
      {/* <HeroById /> */}
      
      <div className="grid xs:grid-rows-2 md:grid-rows-1 gap-4 mt-4  md:grid-flow-col ">



{/* TODO change image based on rocket name */}
 {/* {rocketData.name === "Falcon 9" ? "true" : "false"} */}


  <div className="bg-gray-50 xs:col-span-12 md:col-span-4 md:p-32  md:place-self-center  ...">
    <h1 className='text-2xl font-semibold text-gray-600'>{launchId.name}</h1>
    <ul className='ml-3 text-base	 text-gray-500'>
      Highlights
      <li>
        <ul className="list-square list-inside ml-3">
          <li>Launch date {moment(launchId.date_local).format('MM/DD/YYYY')}</li>
          <li>{launchId.success === true ? <>Mission was a <span className="text-green-500">Success</span></> : <>Mission <span className="text-red-500">Failed</span></>}</li>
          <li>Number of flights {launchId.flight_number}</li>
        </ul>
      </li>
    </ul>
  </div>

  <div className="bg-green-200  xs:col-span-12 md:col-span-6 md:row-span-6 md:h-96 ...">
    {launchId && launchId.links.webcast !== null ? 
  <ReactPlayer
      url={launchId && launchId.links.webcast}
      className='react-player'
      // playing
      // controls='true'
      width='100%'
      height='100%'
    />
    : ""}
  </div>
</div>

<section className='grid xs:grid-rows-3 md:grid-rows-1 gap-4 mt-4  md:grid-flow-col lg:border-t-2  lg:border-gray-200  md:text-left' >

  <article className="  xs:col-span-12 md:col-span-2 lg:border-r-2 lg:border-gray-200 px-2 md:pt-3  ...">
    <h3 className='text-xl font-medium text-gray-600'>
      Launch info
    </h3>
    
     {launchId && launchId.links.patch.small !== null ? <p className="">Patch of {launchId.name} <span><img className="rounded w-20 h-20 inline pl-1 pb-1" src={launchId && launchId.links.patch.small} alt=""  /></span> </p> : "" } 
    {/* {launchId && launchId.details !== null ? <p className="text-gray-600 font-semibold">Details: <span className="text-gray-600 font-normal">{launchId.details}</span></p>:""} */}
    {launchId && launchId.active === true ? <p className="text-gray-600 font-semibold">Active status: <span className="text-green-600 font-normal">Active</span></p>: <p className="text-gray-600 font-semibold">Active status: <span className="text-red-600 font-normal">Not Active</span></p>}
    {cores && cores.serial !== null ? <p className="text-gray-600 font-semibold">Core type: <span className="text-gray-600 font-normal">{cores.serial}</span></p>:""}
    {cores && cores.status !== null ?  <p className="text-gray-600 font-semibold">Status: <span className="text-gray-600 font-normal">{cores.status}</span></p>:""}
    {launchId && launchId.failures !== null ? 
      <> {launchId.failures.map((failure) => (
        <>
          <p className="text-gray-600 font-semibold">Reason for failure: <span className="text-gray-600 font-normal">{failure.reason}</span></p>
          <p className="text-gray-600 font-semibold">Time of failure into flight: <span className="text-gray-600 font-normal">{failure.time}</span></p>
          <p className="text-gray-600 font-semibold">Altitude of failure: <span className="text-gray-600 font-normal">{failure.altitude !== null ? <>{failure.altitude}</>: <>Unknown</>}</span></p>
        </>
        ))}
      </> 
    : "" }
    {/* {launchId && launchId.failures !== null ? <p className="text-gray-600 font-semibold"><span className="text-gray-600 font-normal"></span></p> : "" } */}
  
    {launchId && launchId.links.wikipedia !== null ? <p> <a href={launchId &&launchId.links.wikipedia} >Read about it on wiki</a></p> : "" }
  </article>



  <RocketInfo name={rocketData.name} active={rocketData.active} stages={rocketData.stages} boosters={rocketData.boosters}
  first_flight={rocketData.first_flight} company={rocketData.company} country={rocketData.country} 
  success_rate_pct={rocketData.succes_rate_pct} cost_per_launch={rocketData.cost_per_launch} description={rocketData.description}
  />

  <PayloadInfo name={payloadById.name} type={payloadById.type} customers={payloadById.customers}
   nationalities={payloadById.nationalities} mass_kg={payloadById.mass_kg} mass_lbs={payloadById.mass_lbs}
   orbit={payloadById.orbit} regime={payloadById.regime} lifespan_years={payloadById.lifespan_years}
   />

</section>
   
   </div>
  );
};

export default LaunchById;
