import React from 'react';

const PayloadInfo = ({name, type, customers, nationalities, mass_kg, mass_lbs, orbit, regime, lifespan_years}) => {
  return (
    <article className="  xs:col-span-12 md:col-span-2 px-2 md:pt-3 ...">
    <h3 className='text-xl font-medium text-gray-600'>
        Payload info
      </h3>
      <p>Name: { name}</p>
      <p>Type: { type}</p>
      <p>Customers: { customers}</p>
      <p>Nationalities: { nationalities}</p>
      <p>Weight kg: { mass_kg}</p>
      <p>Weight lbs: { mass_lbs}</p>
      <p>Orbit: { orbit}</p>
      <p>Regime: { regime}</p>
      <p>Lifespan: { lifespan_years !== null ? <>{ lifespan_years}</> : "unknown"}</p>
    </article>
  );
};

export default PayloadInfo;
